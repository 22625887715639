import {
    AspectRatio,
    Box,
    Center,
    Flex,
    Icon,
    Image,
    Link,
    Text,
    Spacer,
    SystemStyleObject,
} from "@chakra-ui/react";
import SkeletonContainerBox from "@components/ui/SkeletonContainerBox";
import NextLink from "next/link";
import NextImage from "next/image";
import { useEffect, useRef, useState } from "react";
import { BsGlobe } from "react-icons/bs";
import { MdOutlineImageNotSupported } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import "@utils/number.extensions";

type ProjectCardProps = {
    collection: CollectionBaseData;
    useFallback?: boolean;
    w?: number | string;
    small?: boolean;
    noStatsMode?: boolean;
};

const IDLE_STYLE: SystemStyleObject = {
    bgColor: "arsenic",
    boxShadow: "1px 5px 8px 0px rgba(0,0,0,0.25)",
    transform: "translateY(0px)",
    transition: "all .25s ease-out",
};

const TOUCHED_STYLE: SystemStyleObject = {
    bgColor: "arsenicLight",
    boxShadow: "1px 2px 4px 0px rgba(0,0,0,0.65)",
    transform: "translateY(2px)",
    transition: "all .1s ease-out",
};

const HOVER_STYLE: SystemStyleObject = {
    bgColor: "arsenicLight",
    boxShadow: "3px 10px 17px 2px rgba(0,0,0,0.65)",
    transform: "translateY(-3px)",
};

const ProjectCard = ({
    collection,
    useFallback = true,
    w,
    small = false,
    noStatsMode = false,
}: ProjectCardProps) => {
    const [collectionId, setCollectionId] = useState(collection?.collectionId);
    const [imageStatus, setImageStatus] = useState<"loading" | "loaded" | "error">("loading");
    const preventHover = useRef<boolean>(false);
    const touchEnded = useRef<boolean>(false);
    const [btnStyle, setBtnStyle] = useState<SystemStyleObject>(() => IDLE_STYLE);
    const card = useRef<HTMLDivElement>();
    const tokenImgAlt = collection?.name
        ? `Beyond Rarity - Featuring ${collection?.name}`
        : `Beyond Rarity - Featured Collection`;

    useEffect(() => {
        if (collection?.collectionId !== collectionId) setCollectionId(collection?.collectionId);
    }, [collection]);

    const onWindowTouchEnd = () => {
        window.removeEventListener("touchend", onWindowTouchEnd);
        preventHover.current = false;
        touchEnded.current = true;
        setBtnStyle(IDLE_STYLE);
    };

    const onTouchStart = () => {
        preventHover.current = true;
        touchEnded.current = false;

        if (typeof window !== undefined) window.addEventListener("touchend", onWindowTouchEnd);
        setBtnStyle(TOUCHED_STYLE);
    };

    const onMouseOut = () => {
        if (preventHover.current) return;
        preventHover.current = false;
        setBtnStyle(IDLE_STYLE);
    };

    const onHover = () => {
        if (preventHover.current || touchEnded.current) return;
        setBtnStyle(HOVER_STYLE);
    };

    const onPressed = () => {
        if (preventHover.current || touchEnded.current) return;
        setBtnStyle(TOUCHED_STYLE);
    };

    const onImageError = () => {
        setImageStatus("error");
    };

    const onImageLoad = () => {
        setImageStatus("loaded");
    };

    const renderImageStatus = () => {
        if (imageStatus === "error") {
            return (
                <Center flexDirection="column" fontSize="14px" bg="app.light" h="100%" w="100%">
                    <Icon
                        as={MdOutlineImageNotSupported}
                        fontSize="100px"
                        color="whiteAlpha.500"
                        w="40px"
                        h="40px"
                    />
                    <Box p="10px" color="whiteAlpha.500">
                        No Image Available
                    </Box>
                </Center>
            );
        }

        return null;
    };

    const stats = collection?.stats;

    const volumeValue =
        stats?.volumeAllTime == null
            ? "--"
            : stats?.volumeAllTime >= 1000
            ? stats?.volumeAllTime.abbreviate(2)
            : stats?.volumeAllTime >= 100
            ? stats?.volumeAllTime.toFixed(Math.min(stats?.volumeAllTime.countDecimals(), 2))
            : stats?.volumeAllTime >= 10
            ? stats?.volumeAllTime.toFixed(Math.min(stats?.volumeAllTime.countDecimals(), 3))
            : stats?.volumeAllTime.toFixed(Math.min(stats?.volumeAllTime.countDecimals(), 3));
    const volumeLabel = volumeValue.length > 3 ? "vol" : "vol";

    const floorValue =
        stats?.floor == null
            ? "--"
            : stats?.floor >= 1000
            ? stats?.floor.abbreviate(2)
            : stats?.floor >= 100
            ? stats?.floor.toFixed(Math.min(stats?.floor.countDecimals(), 2))
            : stats?.floor >= 10
            ? stats?.floor.toFixed(Math.min(stats?.floor.countDecimals(), 3))
            : stats?.floor.toFixed(Math.min(stats?.floor.countDecimals(), 3));

    const floorLabel = floorValue.length > 3 ? "fl" : "fl";

    if (!collection) {
        return (
            <Box
                ref={card}
                rounded="8px"
                overflow="hidden"
                cursor={collection ? "pointer" : undefined}
                transition="all .25s ease-out"
                w={w}
                sx={btnStyle}
                onClick={onPressed}
                onTouchStart={onTouchStart}
                onTouchEnd={onMouseOut}
                onMouseOver={onHover}
                onMouseOut={onMouseOut}
                onMouseLeave={onMouseOut}
            >
                <Box pos="relative">
                    <Box pos="relative">
                        <SkeletonContainerBox
                            bg="spaceCadetLight"
                            isLoaded={!!collection && imageStatus === "loaded"}
                        >
                            <AspectRatio w="100%" ratio={1}>
                                {/* <>
                                    {collection && (
                                        <NextImage
                                            src={collection?.imageUrl || ""}
                                            alt={tokenImgAlt}
                                            objectFit="contain"
                                            layout="fill"
                                            onError={onImageError}
                                            onLoadingComplete={onImageLoad}
                                            priority
                                        />
                                    )}
                                    {renderImageStatus()}
                                </> */}
                                <Image
                                    src={collection?.imageUrl}
                                    alt={tokenImgAlt}
                                    objectFit="contain"
                                    onError={onImageError}
                                    onLoad={onImageLoad}
                                    fallback={
                                        useFallback ? (
                                            collection && !collection?.imageUrl ? (
                                                <>
                                                    <Flex
                                                        direction="column"
                                                        fontSize="18px"
                                                        bg="app.light"
                                                        h="100%"
                                                        w="100%"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Icon
                                                            as={MdOutlineImageNotSupported}
                                                            fontSize="100px"
                                                            color="whiteAlpha.500"
                                                        />
                                                        <Box p="10px" color="whiteAlpha.500">
                                                            No Image Available
                                                        </Box>
                                                    </Flex>
                                                </>
                                            ) : (
                                                <Box>
                                                    {/* <Spinner
                                                    thickness="4px"
                                                    speed="0.65s"
                                                    emptyColor="gray.200"
                                                    color="steelblue.500"
                                                    w="15%"
                                                    h="15%"
                                                /> */}
                                                </Box>
                                            )
                                        ) : (
                                            <Box />
                                        )
                                    }
                                />
                            </AspectRatio>
                        </SkeletonContainerBox>
                        {collection?.projectionIsPublic && (
                            <Box>
                                <Image
                                    alt="features-project-image"
                                    position="absolute"
                                    top="0px"
                                    right="20px"
                                    w="30px"
                                    src={"/images/featured-project.png"}
                                />
                            </Box>
                        )}
                        <Flex
                            pos="absolute"
                            bottom="-2px"
                            w="100%"
                            p="10% 10px 7px 10px"
                            gap="8px"
                            bgGradient={
                                btnStyle === IDLE_STYLE
                                    ? "linear(to-b, spaceCadetCardAlpha.0, arsenic)"
                                    : "linear(to-b, spaceCadetLight, arsenic)"
                            }
                            textStyle="site.cardFooterText"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {noStatsMode ? (
                                <Box visibility="hidden">hidden</Box>
                            ) : (
                                <>
                                    <Flex flex={1}>
                                        <SkeletonContainerBox
                                            isLoaded={collection !== null}
                                            bg="#394057"
                                        >
                                            <Text noOfLines={1}>
                                                {collection
                                                    ? collection.name
                                                    : "unknown collection"}
                                            </Text>
                                        </SkeletonContainerBox>
                                    </Flex>
                                    <Flex
                                        display={
                                            collection?.externalUrl || collection == null
                                                ? undefined
                                                : "none"
                                        }
                                    >
                                        <SkeletonContainerBox
                                            isLoaded={collection !== null}
                                            borderRadius="50%"
                                            bg="#394057"
                                        >
                                            <Link
                                                onClick={(e) => e.stopPropagation()}
                                                href={collection?.externalUrl}
                                                isExternal
                                                display="flex"
                                                _hover={{ textDecor: "none", color: "sorbet" }}
                                            >
                                                <Flex>
                                                    <Icon as={BsGlobe} fontSize="24px" />
                                                </Flex>
                                            </Link>
                                        </SkeletonContainerBox>
                                    </Flex>
                                </>
                            )}
                        </Flex>
                    </Box>
                    <Flex
                        w="100%"
                        p="8px"
                        textStyle="site.p"
                        fontSize={small ? "12px" : "16px"}
                        minH={small ? "16px" : "25px"}
                        lineHeight="100%"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {noStatsMode ? (
                            <SkeletonContainerBox isLoaded={collection !== null} bg="#394057">
                                <Box noOfLines={1} fontSize="110%" textAlign="center">
                                    {collection ? collection.name : "unknown collection"}
                                </Box>
                            </SkeletonContainerBox>
                        ) : (
                            <>
                                <SkeletonContainerBox
                                    isLoaded={!!stats}
                                    minH={small ? "13px" : "17px"}
                                    bg="#394057"
                                >
                                    <Flex justifyContent="center" alignItems="center">
                                        <Icon as={FaEthereum} color="lavenderGray" ml="-4px" />
                                        <Box>
                                            {volumeValue} {volumeLabel}
                                        </Box>
                                    </Flex>
                                </SkeletonContainerBox>
                                <Spacer />
                                <SkeletonContainerBox
                                    isLoaded={!!stats}
                                    minH={small ? "13px" : "17px"}
                                    bg="#394057"
                                >
                                    <Flex justifyContent="center" alignItems="center">
                                        <Icon as={FaEthereum} color="lavenderGray" />
                                        <Box>
                                            {floorValue} {floorLabel}
                                        </Box>
                                    </Flex>
                                </SkeletonContainerBox>
                            </>
                        )}
                    </Flex>
                </Box>
            </Box>
        );
    }

    return (
        <NextLink
            href={!collection ? null : `/c/${collection?.slug}`}
            passHref
            shallow={true}
            legacyBehavior
        >
            <a>
                <Box
                    ref={card}
                    rounded="8px"
                    overflow="hidden"
                    cursor={collection ? "pointer" : undefined}
                    transition="all .25s ease-out"
                    w={w}
                    sx={btnStyle}
                    onClick={onPressed}
                    onTouchStart={onTouchStart}
                    onTouchEnd={onMouseOut}
                    onMouseOver={onHover}
                    onMouseOut={onMouseOut}
                    onMouseLeave={onMouseOut}
                >
                    <Box pos="relative">
                        <Box pos="relative">
                            <SkeletonContainerBox
                                bg="spaceCadetLight"
                                isLoaded={!!collection && imageStatus === "loaded"}
                            >
                                <AspectRatio w="100%" ratio={1}>
                                    {/* <>
                                    {collection && (
                                        <NextImage
                                            src={collection?.imageUrl || ""}
                                            alt={tokenImgAlt}
                                            objectFit="contain"
                                            layout="fill"
                                            onError={onImageError}
                                            onLoadingComplete={onImageLoad}
                                            priority
                                        />
                                    )}
                                    {renderImageStatus()}
                                </> */}
                                    <Image
                                        src={collection?.imageUrl}
                                        alt={tokenImgAlt}
                                        objectFit="contain"
                                        onError={onImageError}
                                        onLoad={onImageLoad}
                                        fallback={
                                            useFallback ? (
                                                collection && !collection?.imageUrl ? (
                                                    <>
                                                        <Flex
                                                            direction="column"
                                                            fontSize="18px"
                                                            bg="app.light"
                                                            h="100%"
                                                            w="100%"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Icon
                                                                as={MdOutlineImageNotSupported}
                                                                fontSize="100px"
                                                                color="whiteAlpha.500"
                                                            />
                                                            <Box p="10px" color="whiteAlpha.500">
                                                                No Image Available
                                                            </Box>
                                                        </Flex>
                                                    </>
                                                ) : (
                                                    <Box>
                                                        {/* <Spinner
                                                    thickness="4px"
                                                    speed="0.65s"
                                                    emptyColor="gray.200"
                                                    color="steelblue.500"
                                                    w="15%"
                                                    h="15%"
                                                /> */}
                                                    </Box>
                                                )
                                            ) : (
                                                <Box />
                                            )
                                        }
                                    />
                                </AspectRatio>
                            </SkeletonContainerBox>
                            {collection?.projectionIsPublic && (
                                <Box>
                                    <Image
                                        alt="features-project-image"
                                        position="absolute"
                                        top="0px"
                                        right="20px"
                                        w="30px"
                                        src={"/images/featured-project.png"}
                                    />
                                </Box>
                            )}
                            <Flex
                                pos="absolute"
                                bottom="-2px"
                                w="100%"
                                p="10% 10px 7px 10px"
                                gap="8px"
                                bgGradient={
                                    btnStyle === IDLE_STYLE
                                        ? "linear(to-b, spaceCadetCardAlpha.0, arsenic)"
                                        : "linear(to-b, spaceCadetLightAlpha.0, arsenic)"
                                }
                                textStyle="site.cardFooterText"
                                justifyContent="center"
                                alignItems="center"
                            >
                                {noStatsMode ? (
                                    <Box visibility="hidden">hidden</Box>
                                ) : (
                                    <>
                                        <Flex flex={1}>
                                            <SkeletonContainerBox
                                                isLoaded={collection !== null}
                                                bg="#394057"
                                            >
                                                <Text noOfLines={1}>
                                                    {collection
                                                        ? collection.name
                                                        : "unknown collection"}
                                                </Text>
                                            </SkeletonContainerBox>
                                        </Flex>
                                        <Flex
                                            display={
                                                collection?.externalUrl || collection == null
                                                    ? undefined
                                                    : "none"
                                            }
                                        >
                                            <SkeletonContainerBox
                                                isLoaded={collection !== null}
                                                borderRadius="50%"
                                                bg="#394057"
                                            >
                                                <Link
                                                    onClick={(e) => e.stopPropagation()}
                                                    href={collection?.externalUrl}
                                                    isExternal
                                                    display="flex"
                                                    _hover={{ textDecor: "none", color: "sorbet" }}
                                                >
                                                    <Flex>
                                                        <Icon as={BsGlobe} fontSize="24px" />
                                                    </Flex>
                                                </Link>
                                            </SkeletonContainerBox>
                                        </Flex>
                                    </>
                                )}
                            </Flex>
                        </Box>
                        <Flex
                            w="100%"
                            p="8px"
                            textStyle="site.p"
                            fontSize={small ? "12px" : "16px"}
                            minH={small ? "16px" : "25px"}
                            lineHeight="100%"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {noStatsMode ? (
                                <>
                                    <SkeletonContainerBox
                                        isLoaded={collection !== null}
                                        bg="#394057"
                                    >
                                        <Box noOfLines={1} fontSize="110%" textAlign="center">
                                            {collection ? collection.name : "unknown collection"}
                                        </Box>
                                    </SkeletonContainerBox>
                                </>
                            ) : (
                                <>
                                    <SkeletonContainerBox
                                        isLoaded={!!stats}
                                        minH={small ? "13px" : "17px"}
                                        bg="#394057"
                                    >
                                        <Flex justifyContent="center" alignItems="center">
                                            <Icon as={FaEthereum} color="lavenderGray" ml="-4px" />
                                            <Box>
                                                {volumeValue} {volumeLabel}
                                            </Box>
                                        </Flex>
                                    </SkeletonContainerBox>
                                    <Spacer />
                                    <SkeletonContainerBox
                                        isLoaded={!!stats}
                                        minH={small ? "13px" : "17px"}
                                        bg="#394057"
                                    >
                                        <Flex justifyContent="center" alignItems="center">
                                            <Icon as={FaEthereum} color="lavenderGray" />
                                            <Box>
                                                {floorValue} {floorLabel}
                                            </Box>
                                        </Flex>
                                    </SkeletonContainerBox>
                                </>
                            )}
                        </Flex>
                    </Box>
                </Box>
            </a>
        </NextLink>
    );
};

export default ProjectCard;
