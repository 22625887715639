import React from "react";
import { Box, BoxProps } from "@chakra-ui/react";

type SkeletonContainerBoxProps = { isLoaded: boolean } & BoxProps;

const SkeletonContainerBox = ({
    isLoaded,
    children,
    ...props
}: SkeletonContainerBoxProps): JSX.Element => {
    const background = !isLoaded
        ? "linear-gradient(to right, transparent 0%, #ffffff08 50%, transparent 100%)"
        : "linear-gradient(to right, transparent 0%, transparent 100%)";
    const animation = !isLoaded ? "skeleton-lg 1.35s linear infinite" : "none";

    const beforeAnimElement = {
        content: '""',
        display: "block",
        position: "absolute",
        left: "-50%",
        top: 0,
        height: "100%",
        width: "80%",
        background: background,
        animation: animation,
    };

    return (
        <Box
            position="relative"
            overflow="hidden"
            _before={!isLoaded ? beforeAnimElement : {}}
            {...props}
            bg={isLoaded ? "transparent" : props.bg || "spaceCadetLight"}
        >
            <Box opacity={isLoaded ? 1 : 0} transition="opacity .25s">
                {children}
            </Box>
        </Box>
    );
};

export default SkeletonContainerBox;
