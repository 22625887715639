declare global {
    interface Number {
        round(number): number;
        countDecimals(): number;
        abbreviate(number): string;
        abbreviate(decimalsForOver1K: number, decimalsForLessThank1k?: number, decimalsForBetween0And1?: number): string;
        truncate(number): number;
        stringTruncate(number): string;
    }
}

Number.prototype.round = function (places: number) {
    return +(
        Math.round(+(this.toString() + "e+" + places.toString())).toString() +
        "e-" +
        places.toString()
    );
};

Number.prototype.countDecimals = function () {
    if (Math.floor(this.valueOf()) === this.valueOf()) return 0;

    var str = this.toString();
    // if (str.indexOf(".") !== -1 && str.indexOf("-") !== -1) {
    //     return str.split("-")[1] || 0;
    // } else 
    
    if (str.indexOf(".") !== -1) {
        return str.split(".")[1].length || 0;
    }
    return 0;
};

Number.prototype.abbreviate = function (decimalsForOver1K: number, decimalsForLessThank1k: number = 18, decimalsForBetween0And1 = 18) : string {
    let result = "";
    const abs = Math.abs(this);
    const y = Math.log10(abs);
    const yFloor = Math.floor(y);
    // console.debug(yFloor);

    if (yFloor > 15) result = `${this < 0 ? "< -" : "> "}9999T`;
    else if (yFloor >= 12)
        result = (this / 10 ** 12).truncate(decimalsForOver1K).toFixed(decimalsForOver1K) + "T"; //.replace(/0+$/i, "").replace(/\.$/i, "") + "T";
    else if (yFloor >= 9)
        result = (this / 10 ** 9).truncate(decimalsForOver1K).toFixed(decimalsForOver1K)+ "B"; //.replace(/0+$/i, "").replace(/\.$/i, "") + "B";
    else if (yFloor >= 6)
        result = (this / 10 ** 6).truncate(decimalsForOver1K).toFixed(decimalsForOver1K) + "M"; //.replace(/0+$/i, "").replace(/\.$/i, "") + "M";
    else if (yFloor >= 3)
        result = (this / 10 ** 3).truncate(decimalsForOver1K).toFixed(decimalsForOver1K) + "K"; //.replace(/0+$/i, "").replace(/\.$/i, "") + "K";
    else if (abs < 1)
        result = this.round(decimalsForBetween0And1).toString();
    else result = this.round(decimalsForLessThank1k).toString();

    // console.debug(`${result}`);

    return result;
};

Number.prototype.truncate = function (numOfDecimals : number) : number {
    var theNumber = this.toString();
    var pointIndex = theNumber.indexOf('.');
    return +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
}
Number.prototype.stringTruncate = function (numOfDecimals : number) : string {
    var theNumber = this.toString();
    var pointIndex = theNumber.indexOf('.');
    return (theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
}

export {};
